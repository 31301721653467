import ReactOnRails from 'react-on-rails';
import App from '../bundles/web/App';
import '../styles/web/index.scss';
import sentryInit from 'web/utils/sentry-init';

sentryInit();

ReactOnRails.register({
  App,
});
