import { set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  pageTitle?: string[];
}

// Maintains an array of page title segments, that can be joined to form a page title
// It returns helper functions that  manipulate the segments, which will likely need to be improved over time.
const HeaderMeta = ({ pageTitle = [] }: Props) => {
  const titleConstant = 'Threadable'; // stays the end of all titles

  const _pageTitle = [...pageTitle, titleConstant].join(' · ');

  return (
    <Helmet>
      <title>{_pageTitle}</title>
    </Helmet>
  );
};

export default HeaderMeta;
