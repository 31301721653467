import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import store, { RootState } from 'web/store';
import { FeatureFlag } from './types';
import { useGetFeatureFlags } from 'web/api/featureFlags';
import { FeatureFlagName } from 'erb/rendered/backend-driven-types';
import { useSession } from 'web/api/session';
import { User } from '../users/types';
import { useEffect } from 'react';

type FeatureFlagSlice = {
  live: FeatureFlag[];
  notLive: FeatureFlag[];
  hasLoadedFeatures: boolean;
};

// const initialState: FeatureFlag[] = [];
const initialState: FeatureFlagSlice = {
  live: [],
  notLive: [],
  hasLoadedFeatures: false,
};

export const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlags: (
      state,
      action: PayloadAction<{ featureFlags: FeatureFlag[]; sessionUser: User | undefined }>,
    ) => {
      const { featureFlags, sessionUser } = action.payload;

      // reset every call or we get dupes when session returns
      state.live = [];
      state.notLive = [];

      featureFlags.forEach(featureFlag => {
        if (featureFlag.is_live || (featureFlag.is_admin_live && sessionUser?.role === 'admin')) {
          state.live.push(featureFlag);
        } else {
          state.notLive.push(featureFlag);
        }
      });
      state.hasLoadedFeatures = true;
    },
  },
});

// gets feature flags from api and sets them in the store
// called once on app load
export const useInitFeatureFlags = () => {
  const { data: sessionUser } = useSession();
  const dispatch = useDispatch();
  const { data: featureFlags = [] } = useGetFeatureFlags();

  useEffect(() => {
    dispatch(setFeatureFlags({ featureFlags, sessionUser }));
  }, [featureFlags, sessionUser]);
};

// use in code to determine if a feature flag is live
// ex: featureIsLive('my-feature-flag')
// non-hook version
export const featureIsLive = (name: FeatureFlagName) => {
  const liveFeatures = store.getState().featureFlags.live;
  const hasLoadedFeatures = store.getState().featureFlags.hasLoadedFeatures;

  return {
    isLive: liveFeatures.some(f => f.name === name),
    hasLoadedFeatures,
  };
};

// hook version
export const useFeatureIsLive = (name: FeatureFlagName) => {
  const liveFeatures = useSelector((state: RootState) => state.featureFlags.live);
  const hasLoadedFeatures = useSelector((state: RootState) => state.featureFlags.hasLoadedFeatures);

  return {
    isLive: !!liveFeatures.find(f => f.name === name),
    hasLoadedFeatures,
  };
};

// Action creators are generated for each case reducer function
const { setFeatureFlags } = featureFlagsSlice.actions;

export default featureFlagsSlice.reducer;
