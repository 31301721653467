import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { api } from 'web/api';
import { FeatureFlag } from 'web/store/feature-flags/types';

export const useGetFeatureFlags = () => {
  return useQuery([queryKeys.featureFlags.index], async () => {
    const response = await api.get('/feature-flags');
    return response.data as FeatureFlag[];
  });
};
