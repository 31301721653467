import { userInteraction } from 'web/utils/analytics/google-analytics';

export const navigatedToFeedback = () => {
  userInteraction('feedback_open', {
    action: 'opened feedback',
    label: 'Opened feedback form',
    title: 'Feedback',
  });
};

export const selectedDarkMode = () => {
  userInteraction('dark_mode_selected', {
    action: 'selected dark mode',
    label: 'Selected dark mode',
    title: 'Settings',
  });
};

export const selectedLightMode = () => {
  userInteraction('light_mode_selected', {
    action: 'selected light mode',
    label: 'Selected light mode',
    title: 'Settings',
  });
};

export const selectedSystemMode = () => {
  userInteraction('system_mode_selected', {
    action: 'selected system mode',
    label: 'Selected system mode',
    title: 'Settings',
  });
};
