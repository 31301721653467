import { userInteraction } from 'web/utils/analytics/google-analytics';

export const openedSettings = () => {
  userInteraction('settings_open', {
    action: 'opened settings',
    label: 'Opened settings',
    title: 'Global nav',
  });
};

export const openedNotifications = () => {
  userInteraction('notification_open', {
    action: 'opened notifications',
    label: 'Opened notifications',
    title: 'Global nav',
  });
};
