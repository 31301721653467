import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { localStorageKeys } from 'web/utils/local-storage-keys';
import localStore from 'store2';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import {
  getColorModeFromLocalStorageOrSystem,
  updateBodyColorModeClass,
} from 'web/utils/color-mode';
import { useEffect } from 'react';

const colorModeKey = localStorageKeys.settings.colorModeStorageKey;

export type ColorMode = 'light' | 'dark';
export type ColorModeKey = 'light' | 'dark' | 'system';
type BackgroundAnimation = 'threads' | null;

interface SettingsSliceState {
  colorMode: ColorMode;
  colorModeKey: ColorModeKey;
  backgroundAnimation: BackgroundAnimation;
  feedScrollPosition: number;
}

export const initialState: SettingsSliceState = {
  colorMode: 'light', // stored value or system default
  colorModeKey: 'system',
  backgroundAnimation: null, // allows a component to set the background animation of app layout
  feedScrollPosition: 0,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setBackgroundAnimation: (
      state: SettingsSliceState,
      action: PayloadAction<BackgroundAnimation>,
    ) => {
      state.backgroundAnimation = action.payload;
    },
    setColorModeByKey: (state: SettingsSliceState, action: PayloadAction<ColorModeKey>) => {
      // persist key to local storage
      localStore.set(colorModeKey, action.payload);
      state.colorModeKey = action.payload;

      // set mode in state based on stored key or system default
      const mode = getColorModeFromLocalStorageOrSystem();
      state.colorMode = mode;
      updateBodyColorModeClass({ body: document.body, colorMode: mode });
    },
    setFeedScrollPosition: (state: SettingsSliceState, action: PayloadAction<number>) => {
      state.feedScrollPosition = action.payload;
    },
  },
});

export const useSettingsState = (): SettingsSliceState =>
  useSelector((state: RootState) => state.settings);

// Action creators are generated for each case reducer function
export const { setColorModeByKey, setBackgroundAnimation, setFeedScrollPosition } =
  settingsSlice.actions;

// Allow components to request a bg animation and ensure they unset it when they unmount or
// it stays set foreeeever
export const useBackgroundAnimation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBackgroundAnimation('threads'));

    return () => {
      dispatch(setBackgroundAnimation(null));
    };
  }, []);
};
export default settingsSlice.reducer;
