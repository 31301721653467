import { userInteraction } from 'web/utils/analytics/google-analytics';

export const pinnedComment = (commentId: string) => {
  userInteraction('comment_pinned', {
    action: `pinned comment ${commentId}`,
    label: 'Pinned comment',
    title: 'Circle Home',
  });
};

export const clickedMembersTab = () => {
  userInteraction('members_tab_click', {
    action: 'clicked members tab',
    label: 'Clicked members tab',
    title: 'Circle Home',
  });
};

export const clickedCommunityTab = () => {
  userInteraction('community_tab_click', {
    action: 'clicked community tab',
    label: 'Clicked community tab',
    title: 'Circle Home',
  });
};

export const clickedCircleShare = () => {
  userInteraction('circle_share_click', {
    action: 'clicked circle share',
    label: 'Clicked circle share',
    title: 'Circle Home',
  });
};

export const clickedCircleSettings = () => {
  userInteraction('circle_settings_click', {
    action: 'clicked circle settings',
    label: 'Clicked circle settings',
    title: 'Circle Home',
  });
};

export const clickedCircleAddRead = () => {
  userInteraction('circle_add_read_click', {
    action: 'clicked circle add read',
    label: 'Clicked circle add read',
    title: 'Circle Home',
  });
};

export const editedRead = () => {
  userInteraction('edited_read', {
    action: 'edited read',
    label: 'Edited read',
    title: 'Circle Home',
  });
};

export const pinnedRead = () => {
  userInteraction('pinned_read', {
    action: 'pinned read',
    label: 'Pinned read',
    title: 'Circle Home',
  });
};

export const removedRead = () => {
  userInteraction('removed_read', {
    action: 'removed read',
    label: 'Removed read',
    title: 'Circle Home',
  });
};

export const deletedCircle = (circleName: string) => {
  userInteraction('circle_deleted', {
    action: `deleted circle ${circleName}`,
    label: 'Deleted circle',
    title: 'Circle Home',
  });
};

export const editedCircleCoverImage = () => {
  userInteraction('circle_cover_image_edited', {
    action: 'edited circle cover image',
    label: 'Edited circle cover image',
    title: 'Circle Home',
  });
};

export const clickedMakeLeader = () => {
  userInteraction('make_circle_leader', {
    action: 'clicked make leader',
    label: 'Clicked make leader',
    title: 'Circle Home',
  });
};

export const clickedRemoveUser = () => {
  userInteraction('remove_circle_user', {
    action: 'clicked remove user',
    label: 'Clicked remove user',
    title: 'Circle Home',
  });
};

export const likedCommentOnCircleLanding = () => {
  userInteraction('liked_comment_on_circle_landing', {
    action: 'liked comment on circle landing',
    label: 'Liked comment on circle landing',
    title: 'Circle Home',
  });
};

export const userSharedBookCircle = () => {
  userInteraction('user_shared_book_circle', {
    action: 'user shared book circle',
    label: 'User shared book circle',
    title: 'Circle Home',
  });
};
