import TagManager from '@sooro-io/react-gtm-module';
import { isDevelopment } from 'web/utils/constants';

const gtmContainerId = 'GTM-N38KLNLJ';

export const initializeGTM = () => {
  if (!isDevelopment) {
    const localUserId = localStorage.getItem('userId');
    TagManager.initialize({
      gtmId: gtmContainerId,
      dataLayer: {
        userId: localUserId,
      },
    });
  }
};

export const setUserInGTM = (userId: string) => {
  if (!isDevelopment) {
    TagManager.dataLayer({
      dataLayer: {
        userId,
      },
    });
  }
};
