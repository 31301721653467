import { userInteraction } from 'web/utils/analytics/google-analytics';

export const userSignedIn = () => {
  userInteraction('user_signed_in', {
    action: 'user signed in',
    label: 'User signed in',
    title: 'Session',
  });
};

export const userSignedUp = () => {
  userInteraction('user_signed_up', {
    action: 'user signed up',
    label: 'User signed up',
    title: 'Session',
  });
};

export const userSignedOut = () => {
  userInteraction('user_signed_out', {
    action: 'user signed out',
    label: 'User signed out',
    title: 'Session',
  });
};
