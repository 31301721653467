import { localStorageKeys } from '../local-storage-keys';

export const persistParamsThroughSignUp = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const paramsObject: Record<string, string> = {};

  searchParams.forEach((value, key) => {
    paramsObject[key] = value;
  });

  localStorage.setItem(localStorageKeys.session.urlParamsAfterSignUp, JSON.stringify(paramsObject));
};

export const setParamsFromLocalStorage = () => {
  const params = localStorage.getItem(localStorageKeys.session.urlParamsAfterSignUp);

  if (params) {
    const parsedParams = JSON.parse(params);
    const searchParams = new URLSearchParams(parsedParams);

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
    localStorage.removeItem(localStorageKeys.session.urlParamsAfterSignUp);
  }
};
