import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { APP_ENV, SENTRY_DSN, isDevelopment } from 'web/utils/constants';

const sentryInit = () => {
  Sentry.init({
    dsn: SENTRY_DSN || undefined,
    environment: APP_ENV,
    enabled: !isDevelopment,
    debug: false,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'https://threadable-api.herokuapp.com/*',
      'https://threadable-api-test.herokuapp.com/*',
      'https://threadable-api-prod.herokuapp.com/*',
      'https://*.threadablebooks.com/*',
    ],
    // Session Replay
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
  });
};

export default sentryInit;
