import React from 'react';

import { TbLoader2 } from 'react-icons/tb';

type Props = {
  additionalStyleClasses?: string; // any additional style classes to add to or override the default classes
};

const LoadingSpinner = ({ additionalStyleClasses = '' }: Props) => {
  const classes = {
    loadingSpinner: `loading-spinner fixed inset-0 flex justify-center items-center ${additionalStyleClasses}`,
  };

  return (
    <div aria-live="polite" aria-label="Loading" className={classes.loadingSpinner}>
      <TbLoader2 className="text-aqua h-11 w-11 animate-spin" />
    </div>
  );
};

export default LoadingSpinner;
