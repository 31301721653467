import { useEffect } from 'react';
import { osName } from 'react-device-detect';

const useWindowsOSStyles = () => {
  useEffect(() => {
    const isWindows = osName === 'Windows';

    document.body.classList.toggle('is-windows', isWindows);

    return () => {
      document.body.classList.remove('is-windows');
    };
  }, []);
};

export default useWindowsOSStyles;
