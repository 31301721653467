import { userInteraction } from 'web/utils/analytics/google-analytics';

export const typedInSearchBar = (searchTerm: string) => {
  userInteraction('search_bar_type', {
    action: `typed in search bar: ${searchTerm}`,
    label: 'Typed in search bar',
    title: 'Search',
  });
};

export const clickedSearchResult = () => {
  userInteraction('search_result_click', {
    action: 'clicked search result',
    label: 'Clicked search result',
    title: 'Search',
  });
};
