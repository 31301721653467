// Sets up a global event emitter that can be used to emit and listen to events across the app
// eventemitter3 conforms to https://nodejs.org/api/events.html
//
// Usage:
//   import eventEmitter from 'shared/utils/event-emitter';
//
//   Publish an event:
//     eventEmitter.emit('event-name', data);
//
//   Subscribe to an event:
//     eventEmitter.on('event-name', (data) => {});
//
import { EventEmitter } from 'eventemitter3';

export const eventKeys = {
  discussion: {
    navigateToSection: 'discussion:navigate-section',
    focusedCommentChange: 'discussion:focused-comment-change',
  },
};

const eventEmitter = new EventEmitter();

export default eventEmitter;
