import { userInteraction } from 'web/utils/analytics/google-analytics';

export const clickedEditProfile = () => {
  userInteraction('edit_profile_clicked', {
    action: 'clicked edit profile',
    label: 'Clicked edit profile',
    title: 'User Profile',
  });
};

export const clickedShareProfile = () => {
  userInteraction('share_profile_clicked', {
    action: 'clicked share profile',
    label: 'Clicked share profile',
    title: 'User Profile',
  });
};

export const clickedFollowersList = () => {
  userInteraction('followers_list_clicked', {
    action: 'clicked followers list',
    label: 'Clicked followers list',
    title: 'User Profile',
  });
};

export const clickedFollowingList = () => {
  userInteraction('following_list_clicked', {
    action: 'clicked following list',
    label: 'Clicked following list',
    title: 'User Profile',
  });
};

export const clickedUploadReadProfile = () => {
  userInteraction('upload_read_profile_clicked', {
    action: 'clicked upload read profile',
    label: 'Clicked upload read profile',
    title: 'User Profile',
  });
};

export const uploadedDocumentFromProfile = () => {
  userInteraction('uploaded_document_from_profile', {
    action: 'uploaded document from profile',
    label: 'Uploaded document from profile',
    title: 'User Profile',
  });
};

export const createdCircleFromProfile = () => {
  userInteraction('created_circle_from_profile', {
    action: 'created circle from profile',
    label: 'Created circle from profile',
    title: 'User Profile',
  });
};

export const clickedWebLinkFromProfile = (url: string) => {
  userInteraction('clicked_web_link_from_profile', {
    action: `clicked web link from profile ${url}`,
    label: 'Clicked web link from profile',
    title: 'User Profile',
  });
};

export const clickedLinkTreeLinkFromProfile = (handle: string) => {
  userInteraction('clicked_Link Tree_link_from_profile', {
    action: `clicked Link Tree link from profile ${handle}`,
    label: 'Clicked Link Tree link from profile',
    title: 'User Profile',
  });
};
