import { userInteraction } from 'web/utils/analytics/google-analytics';

export const clickedMoreAboutThisTitle = () => {
  userInteraction('more_about_this_title_click', {
    action: 'clicked more about this title',
    label: 'Clicked more about this title',
    title: 'Document Profile',
  });
};

export const likedCommentOnDocumentProfile = () => {
  userInteraction('liked_comment_on_document_profile', {
    action: 'liked comment on document profile',
    label: 'Liked comment on document profile',
    title: 'Document Profile',
  });
};

export const clickedDocumentShare = () => {
  userInteraction('document_profile_share_click', {
    action: 'clicked document profile share',
    label: 'Clicked document profile share',
    title: 'Document Profile Share',
  });
};
