// A collection of methods related to color mode management

import { setColorModeByKey, ColorMode, ColorModeKey } from 'web/store/settings/slice';
import localStore from 'store2';
import { localStorageKeys } from './local-storage-keys';
import store from 'web/store';

const colorModeStorageKey = localStorageKeys.settings.colorModeStorageKey;

const updateBodyColorModeClassOnSystemChange = () => {
  const storedColorModeKey = localStore.get(colorModeStorageKey);
  const colorModeHasNeverBeenSet = !storedColorModeKey;

  if (colorModeHasNeverBeenSet || storedColorModeKey === 'system') {
    // treat this event as if they were setting the color mode to system
    store.dispatch(setColorModeByKey('system'));
  }
};

// an app-level event listener that will update the body class when the system color mode changes
window
  .matchMedia('(prefers-color-scheme: dark)')
  .addEventListener('change', updateBodyColorModeClassOnSystemChange);

export const updateBodyColorModeClass = ({
  body,
  colorMode,
}: {
  body: Document['body'];
  colorMode: ColorMode;
}) => {
  body.classList.remove('light', 'dark');
  body.classList.add(colorMode);
};

const getSystemColorMode = (): ColorMode => {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return 'dark';
  }
  return 'light';
};

export const getCurrentColorModeKey = (): ColorModeKey | undefined =>
  localStore.get(colorModeStorageKey);

export const getColorModeFromLocalStorageOrSystem = (): ColorMode => {
  const mode = getCurrentColorModeKey();
  if (mode === 'light' || mode === 'dark') {
    return mode;
  }
  return getSystemColorMode();
};
