import React from 'react';
import toast from 'react-hot-toast';
import Toast, { ToastType } from 'web/components/Toast';

type ToastParams = {
  dismissable?: boolean;
  message: string | React.ReactNode;
  type?: ToastType;
  cta?: {
    text: string;
    onClick: () => void;
  };
};

/**
 * Show a toast message
 * @param dismissable Whether the toast has a close button
 * @param message The message to show
 * @param type The type of toast to show
 * @param cta Call to action that will be rendered as a button
 */
export const showToast = ({ dismissable = true, message, type = 'info', cta }: ToastParams) => {
  toast.custom(t => (
    <Toast {...t} dismissable={dismissable} toastMessage={message} toastType={type} cta={cta} />
  ));
};
